import { useFormikContext } from 'formik'
import { ExecutorSelect } from '../styled'
import { useFormikMediator } from 'hooks/useFormikMediator'
import { useRef } from 'react'

export function TastEstimateExecutor({
  estimateIndex,
  executors,
  autosaveContext,
  isAdminOrManager,
  currentUserCanEditEstimate,
  currentUser,
}) {
  const formik = useFormikContext()
  const form = useFormikMediator()
  const formEstimate = useFormikMediator(`estimates[${estimateIndex}]`)

  const estimateExecutorId = formEstimate.get('user_id')
  const allEstimates = form.get('estimates')
  const taskExecutors = form.get(`executors`)
  const estimateExecutor = taskExecutors.find(
    (executor) => executor.id == estimateExecutorId
  )
  const allEstimatesExecutorsIds =
    allEstimates.map((estimate) => estimate.user_id).filter(Boolean) ?? []

  const executorsWithoutSelfExecutor = executors
    .filter((el) => el.id === currentUser.id)
    .filter((el) => !allEstimatesExecutorsIds.includes(el.id))

  const currentExecutors =
    !isAdminOrManager && currentUserCanEditEstimate
      ? executorsWithoutSelfExecutor
      : executors

  const executorSelectRef = useRef(null)
  const onSelectPopup = (open) => () => {
    const scrollview = executorSelectRef.current?.closest(
      '.task-estimate-scrollview'
    )
    if (scrollview) {
      scrollview.style.overflow = open ? 'visible' : ''
      scrollview.style.marginBottom = open ? '20px' : ''
    }
  }

  return (
    <ExecutorSelect
      key={String(estimateExecutor)}
      ref={executorSelectRef}
      options={currentExecutors}
      onChange={(value) => {
        if (!formEstimate.get('estimate')) {
          autosaveContext.resetLastValidEstimate({ user_id: value.id })
        }
        autosaveContext.markEstimateChanged()

        formEstimate.set('user_id', value.id)

        let newExecutors = taskExecutors.filter(
          ({ id }) => id != estimateExecutorId
        )
        if (newExecutors.every(({ id }) => id != value.id)) {
          newExecutors.push(value)
        }
        form.set('executors', newExecutors)

        setTimeout(() => formik.validateForm())
      }}
      onMenuOpen={onSelectPopup(true)}
      onMenuClose={onSelectPopup(false)}
      placeholder="Ответственный *"
      maxHeight="200px"
      meta={formEstimate.getMeta('user_id')}
      value={estimateExecutor}
    />
  )
}
