import styled from 'styled-components'
import Switch from 'ui/components/forms/Switch'

export const EstimateSwitch = styled(Switch)`
  margin: 0px 10px 0px 0px;
  justify-content: end;
`

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`
