import { ControlIcon, ReadonlyCell } from './styled'
import ControlButton from 'ui/components/ControlButton'
import Icon from 'ui/components/Icon'
import { deleteTaskEstimate } from 'api/tasks'
import { safeQuery } from 'helpers/safeQuery'
import { useSelector } from 'react-redux'
import { usePrevilegies } from 'hooks/usePrevilegies'
import { currentUserSelector } from 'store/users'
import { useEstimateAutosaving } from './hooks'
import { useFormikMediator } from 'hooks/useFormikMediator'
import { TastEstimateExecutor } from './components/TaskEstimateExecutor'
import { TaskEstimateValue } from './components/TaskEstimateValue'
import { TaskEstimatePeriod } from './components/TaskEstimatePeriod'

export function TaskEstimate({ index, executors, taskEditPrivileges }) {
  const { isDepartmentHead } = usePrevilegies()
  const { canEditEstimate, isAdminOrManager, currentUserCanEditEstimate } =
    taskEditPrivileges

  const form = useFormikMediator()
  const formEstimate = useFormikMediator(`estimates[${index}]`)

  const estimate = formEstimate.get()
  const estimates = form.get('estimates')
  const currentUser = useSelector(currentUserSelector)
  const taskExecutors = form.get('executors')
  const selectedExecutorId = formEstimate.get('user_id')
  const selectedExecutor = taskExecutors.find(
    (executor) => executor.id == selectedExecutorId
  )

  const autosaveContext = useEstimateAutosaving(index)

  return canEditEstimate || isDepartmentHead ? (
    <>
      {!canEditEstimate ? (
        <span>{selectedExecutor?.value}</span>
      ) : (
        <TastEstimateExecutor
          estimateIndex={index}
          executors={executors}
          autosaveContext={autosaveContext}
          isAdminOrManager={isAdminOrManager}
          currentUserCanEditEstimate={currentUserCanEditEstimate}
          currentUser={currentUser}
        />
      )}
      <TaskEstimateValue
        estimateIndex={index}
        autosaveContext={autosaveContext}
      />
      <TaskEstimatePeriod
        estimateIndex={index}
        autosaveContext={autosaveContext}
      />
      {!canEditEstimate ? (
        <span />
      ) : (
        <ControlButton
          theme="grey"
          css="height:42px;width:30px;"
          onClick={async () => {
            let deleted = true
            if (estimate.id) {
              deleted = !!safeQuery(() => deleteTaskEstimate(estimate.id), {
                successMessage: 'Оценка успешно удалена',
              })
            }
            if (deleted) {
              form.set(
                'estimates',
                estimates.filter((_estimate, i) => i != index)
              )
            }
          }}
        >
          <ControlIcon>
            <Icon name="close" />
          </ControlIcon>
        </ControlButton>
      )}
    </>
  ) : (
    <>
      <ReadonlyCell>
        {selectedExecutor?.value ??
          (selectedExecutorId == currentUser.id ? currentUser.name : null)}
      </ReadonlyCell>
      <ReadonlyCell>{formEstimate.get('estimate')}</ReadonlyCell>
      <ReadonlyCell>
        {estimate.date_start}
        {' - '}
        {estimate.date_end}
      </ReadonlyCell>
      <span />
    </>
  )
}
